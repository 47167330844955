.chapter-preview-cell {
    width: 250px;
    height: 400px;
    border: 1px solid lightgray;
    border-radius: 5px;
    margin: 5px;
    flex-shrink: 0;
    padding: 10px 15px;
    position: relative;
}

.chapter-preview-cell h3 {
    text-align: center;
}

.chapter-preview-cell:link, .chapter-preview-cell:visited {
    text-decoration: none;
    color: black;
}