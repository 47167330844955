.tab-view {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 80px;
}

.tab-control {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid var(--line);
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0px 10px;
}

.tab-item {
    padding: 8px 10px;
    border-bottom: 2px solid transparent;
    transition-duration: 0.2s;
    text-align: center;
    user-select: none;
    cursor: default;
    margin-bottom: -1px;
    font-size: 14px;
    min-width: fit-content;
}

.tab-item + .tab-item {
    margin-left: 0px;
}

.tab-item-selected {
    background-color: #e9e9e9;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.view-container {
    position: relative;
    /* min-height: 200px; */
    /* height: 100%; */
    padding: 0px 10px;
}