.progress-bar-track {
    height: 10px;
    border-radius: 100px;
    background-color: #f0f0f0;
    position: relative;
}

.progress-bar-progress {
    border-radius: 5px;
    height: 100%;
    background-color: #9bb6d7;
    /* transition-timing-function: ease-in-out;
    transition-duration: 0.2s; */
}

.progress-bar-status {
    display: flex;
    justify-content: space-between;
    font-size: 14;
    margin-bottom: 5px;
}