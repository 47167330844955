@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 200;
  src: url(../public/fonts/PublicSans-Thin.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 200;
  src: url(../public/fonts/PublicSans-ThinItalic.woff2) format('woff2');
}


@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 300;
  src: url(../public/fonts/PublicSans-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 300;
  src: url(../public/fonts/PublicSans-LightItalic.woff2) format('woff2');
}


@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 400;
  src: url(../public/fonts/PublicSans-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 400;
  src: url(../public/fonts/PublicSans-Italic.woff2) format('woff2');
}


@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 500;
  src: url(../public/fonts/PublicSans-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 500;
  src: url(../public/fonts/PublicSans-MediumItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 600;
  src: url(../public/fonts/PublicSans-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 600;
  src: url(../public/fonts/PublicSans-SemiBoldItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 700;
  src: url(../public/fonts/PublicSans-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 700;
  src: url(../public/fonts/PublicSans-BoldItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: normal;
  font-weight: 800;
  src: url(../public/fonts/PublicSans-ExtraBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Public Sans';
  font-style: italic;
  font-weight: 800;
  src: url(../public/fonts/PublicSans-ExtraBoldItalic.woff2) format('woff2');
}