.mcq {
    border-radius: 12px;
    background-color: #f1f1f1;
    padding: 18px 20px;
    margin: 15px 8px;
    color: #101010;
}

.mcq-header {
    font-weight: 600;
    font-size: 17px;
    margin: 4px 0px 10px;
}

.mcq-prompt {
    color: #303030;
    font-size: 16px;
}

.mcq-options {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-top: 10px;
}

.mcq-options > button {
    text-align: left;
    background-color: #e8e8e8;
    border: none;
    padding: 9px 12px;
    font-size: 14px;
    /* width: fit-content; */
    margin: 3px 0px;
    border-radius: 6px;
    transition-duration: 0.2s;
    cursor: pointer;
    /* min-width: min(100%, 300px); */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mcq-options > button > span {
    display: inline-block;
    line-height: 1.45;
    vertical-align: bottom;
}

.mcq-options > button:hover {
    background-color: #e0e0e0;
}

.mcq-options .correct-button, .mcq-options .correct-button:hover {
    background: rgb(199, 234, 203);
}

.mcq-options button .markdown-view p:first-child {
    margin-top: 0;
}

.mcq-options .incorrect-button, .mcq-options .incorrect-button:hover {
    background: rgb(239, 195, 190);
}

.mcq .explanation {
    margin-top: 10px;
    color: #000210;
    line-height: 1.25;
}