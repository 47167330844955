.exercise-card {
    background: linear-gradient(to bottom, #fff, #b6deff);
    border-radius: 20px;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    transition: transform 0.2s ease-in-out;
    
    width: 200px;
    height: 250px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.digit-container {
    width: 200px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.digit_0, .digit_1 {
    margin: 0 -15px;
    width: 100px;
    height: 100px;
}

.exercise-details {
  margin-top: 10px;
  text-align: center;
}

.exercise-title {
    margin: 0px 20px;
    font-family: "Futura Bold", sans-serif;
    font-weight: bold;
}

.exercise-score {
    margin-top: 5px;
    font-size: 30px;
}

.exercise-score > img {
    margin: 0px 2px;
}

/* a {
    text-decoration: none;
    color: inherit;
} */
