.instructional-text {
    padding-bottom: 30px;
    background-color: white;
    border-radius: 18px;
    margin-top: 12px;
    margin-bottom: 40px;
}
  
.instructional-text .header {
    padding: 5px 30px;
    border-bottom: 1px solid #f0f0f0;
}


.instructional-text .body {
    padding: 10px 40px;
}
  
.instructional-text .body p {
    line-height: 1.4;
}

.instructional-text .body a {
    color: #5080c1;
}

.instructional-text .body a:hover {
    text-decoration: underline;
}
  
.instructional-text .body .centered {
    display: block;
    margin: 25px auto;
    max-width: 100%;
}

.lesson-back-button {
    border-radius: 8px;
    border: none;
    padding: 7px 13px;
    padding-right: 15px;
    background-color: #e8e8e8;
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    transition-duration: 0.2s;
    color: #101010;
    cursor: pointer;
}

.lesson-back-button:hover {
    background-color: #e0e0e0;
}

.lesson-back-button:active {
    background-color: #d9d9d9;
}

.instructional-text .image-caption {
    /* textAlign: "center", fontSize: "13px", marginTop: -10, fontWeight: 400, color: "#404040" */
    text-align: center;
    font-size: 13px;
    margin: -10px 30px 15px;
    font-weight: 400;
    color: #808080;
}

.instructional-text .next-button {
    background-color: rgb(132, 130, 190);
    color: white;
    border: none;
    display: block;
    font-size: 16px;
    padding: 10px 14px;
    min-width: 150px;
    font-weight: 600;
    border-radius: 20px;
    margin: 15px auto 10px;
    cursor: pointer;
    text-decoration: none;
    transition-duration: 0.2s;
}

.instructional-text .next-button:hover {
    background-color: rgb(120, 118, 174);
}