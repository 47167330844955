.inline-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
}

.inline-table .markdown-view p {
    margin-top: 0;
}