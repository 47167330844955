.test-table {
    position: relative;
}

.test-table .heading {
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
}

.test-table .heading button {
    border-radius: 10px;
    border: none;
    padding: 2px 10px;
    color: rgba(231, 243, 255, 0.85);
    background-color: rgba(107, 129, 145, 0.9);
    cursor: pointer;
    transition-duration: 0.15s;
}

.test-table .heading button:hover {
    background-color: rgba(118, 141, 159, 0.95);
}

.test-table .heading button:active {
    background-color: rgb(131, 155, 173);
    color: rgba(231, 243, 255, 0.9);
}


.test-table .test-case {
    width: 100%;
    padding: 3px 10px;
    background-color: #f5f5f540;
    position: relative;
    border-spacing: 0px 7px;
    margin-bottom: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.test-table .test-case.selected {
    background-color: #ddefff67;
}

.test-case tr td:first-child {
    width: 82px;
    font-size: 14px;
    padding-right: 5px;
}

.test-case tr td:nth-child(2) {
    /* width: calc(100% - 100px); */
    background-color: #ffffffc0;
    border-radius: 4px;
    padding: 1px;
    font-family: monospace;
    color: black;
    overflow-wrap: anywhere;
    max-height: 100px;
    overflow-y: auto;
}

.test-table .MuiDialog-paper {
    border-radius: 8px !important;
}

.test-table .data-type-select {
    background-color: transparent;
    display: block;
    font-size: 13px;
    cursor: pointer;
    border: none;
    color: rgba(171, 192, 208, 0.92);
    outline: none;
    max-width: 85px;
    -webkit-appearance: none;
    appearance: none;
    padding: 0px 4px;
    margin-top: 2px;
    margin-left: -4px;
}

.test-table .data-type-select:hover {
    background-color: rgba(10, 11, 12, 0.1);
}
