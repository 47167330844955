/* .library-item {
    border-bottom: 1px solid var(--line);
    padding: 10px 5px;
    background-color: white;
    user-select: none;
} */

.library-item {
    background-color: rgba(74, 95, 111, 0.908);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin: 8px 0px;
    padding: 10px;
    align-items: center;
    cursor: grab;
    user-select: none;
    max-width: 100%; /* Prevents .item from overflowing its parent */
    overflow: hidden; /* Hides any overflow */
    /* text-overflow: ellipsis; */
  }
  
  .library-item img {
    border-radius: 6px;
  }
  
  .library-item .item-details {
    display: flex;
    flex-direction: column;
  }