.exercise {
    position: relative;
    height: 100%;
    min-height: 500px;
    min-width: 800px;
    overflow: auto;
    color: #f7fcffee;
    background-color: var(--theme-bg);
}

.exercise .split {
    height: 100%;
}

.exercise .sidebar {
    max-width: 500px;
    flex-shrink: 0;
}


.exercise .sidebar .block-library {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 3px 15px;
    box-sizing: border-box;
    background-color: var(--theme-bg);
    color: #fdfefff0;
}


.block-library .subtitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
}

.block-library h3 {
    margin-bottom: 5px;
}

.exercise .blockspace-container {
    position: relative;
    overflow: hidden;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.exercise .blockspace-canvas {
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
    overflow: auto;
    background-color: #f7f7f7;
    user-select: none;
}

.exercise .right-sidebar {
    position: relative;
    overflow-x: hidden;
    flex-shrink: 0;
    flex-grow: 0;
}

.exercise .right-sidebar .inspector-view .menu-bar {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.exercise .right-sidebar .inspector-view .menu-bar > img {
    width: 20px;
    height: 20px;
    padding: 3px;
    border-radius: 4px;
    margin: 1px;
    transition-duration: 0.15s;
}

.exercise .right-sidebar .inspector-view .menu-bar > img:hover {
    background-color: #b2d5ff62;
}

.exercise .right-sidebar .inspector-view .menu-bar > img.selected {
    background-color: var(--code-token);
}

.exercise .right-sidebar .inspector-content {
    position: absolute;
    top: 35px;
    left: 18px;
    width: calc(100% - 36px);
    bottom: 0;
}

.exercise .right-sidebar .close {
    position: absolute;
    left: 5px;
    top: 6px;
    opacity: 0.8;
    transition-duration: 0.1s;
}

.exercise .right-sidebar .close:hover {
    opacity: 0.9;
}

.exercise .right-sidebar .close:active {
    opacity: 1.0;
}

.exercise .konvajs-content {
    width: 100% !important;
}

.exercise .show-inspector-icon {
    position: absolute;
    z-index: 2;
    top: 6px;
    right: 6px;
    cursor: pointer;
}

.exercise .doc {
    padding-bottom: 10px;
}

.exercise .doc p {
    font-size: 13.5px;
}

.exercise .doc .katex-display {
    font-size: 15px;
}

.exercise .markdown-view ul {
    font-size: 13.5px;
}

.exercise .markdown-view .inline-code {
    background-color: var(--code-token);
    color: var(--inline-code);
}

.exercise .instructions table, .inspector-content .doc table {
    margin: 10px auto;
    border-collapse: collapse;
    background-color: rgba(133, 194, 255, 0.15);
    border-radius: 5px;
}

.exercise .instructions th, .exercise .instructions td, .inspector-content .doc th, .inspector-content .doc td {
    border: 1px solid #d0d2d450;
    text-align: center;
    vertical-align: middle;
}

.exercise .instructions .grid th, .exercise .instructions .grid td, .inspector-content .doc .grid th, .inspector-content .doc .grid td {
    width: 30px;
    height: 30px;
}

.exercise .exercise-info {
    padding: 15px;
    box-sizing: border-box;
    overflow-y: auto;
}

.exercise .exercise-info p {
    font-size: 14px;
}

.exercise .exercise-info h3 {
    margin-block-start: 15px;
    margin-block-end: 15px;
}

.exercise .instructions {
    margin-bottom: 15px;
    font-size: 14px;
}

.back-button {
    border-radius: 8px;
    border: none;
    padding: 6px 12px;
    padding-right: 15px;
    background-color: #ebebeb;
    font-size: 13px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    transition-duration: 0.2s;
    color: #101010;
    cursor: pointer;
}

.inspector-content .info-table input, .inspector-content .info-table textarea {
    border: 1px solid #f0f0f058;
}

.inspector-content .alert-button-normal {
    background-color: rgba(97, 117, 132, 0.95);
    color: rgba(231, 243, 255, 0.85);
    border: 1px solid rgba(143, 170, 196, 0.25);
}

.inspector-content .alert-button-normal:hover {
    background-color: rgb(107, 129, 145);
}

.inspector-content .alert-button-normal:active {
    background-color: rgb(120, 142, 159);
    color: rgba(231, 243, 255, 0.9);
}

.inspector-content .menu-select {
    border: 1px solid rgba(143, 170, 196, 0.25);
    border-radius: 20px;
    padding: 2px 10px;
    margin-left: 5px;
    background-color: transparent;
    font-size: 13px;
    min-width: 100px;
    color: rgba(231, 243, 255, 0.85);
    transition-duration: 0.15s;
}

.inspector-content .menu-select:hover {
    background-color: rgba(85, 102, 115, 0.2);
}

.inspector-content .menu-select:active {
    background-color: rgba(95, 112, 126, 0.3);
}

.menu {
    display: fixed;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(143, 170, 196, 0.25);
    margin-left: 2px;
    z-index: 100;
    margin-top: -1px;
    box-shadow: 0px 2px 8px 2px rgba(131, 155, 173, 0.1);
}

.menu button {
    border-style: none;
    display: block;
    padding: 4px 8px;
    box-sizing: border-box;
    cursor: default;
    font-size: 14px;
    user-select: none;
    background-color: #263f52;
    width: 100%;
    color: rgba(231, 243, 255, 0.88);
    text-align: left;
}

.menu button:hover {
    background-color: #2f495c;
}

.menu button:active {
    background-color: #375267;
}