.info-table td:nth-child(2) {
    text-align: right;
}

.info-table td:nth-child(1) {
    padding-right: 10px;
}

.info-table {
    width: 100%;
    border-collapse: collapse;
}

.info-table tr {
    font-size: 14px;
}

.info-table th {
    border: none;
    text-align: left;
    font-size: 15px;
}