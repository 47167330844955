body {
  margin: 0;
  font-family: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --line: #f0f0f0;
  --theme-1: #fa804b;
  --theme-1-shade: #fa804b20;
  --theme-select: #da6a3d;
  --theme-dark: #cd652d;
  --hover-tint: rgba(255, 172, 130, 0.12);
  --focus-tint: rgba(250, 130, 70, 0.25);
  --theme-disabled: rgb(255, 194, 144);
  --theme-shade: rgb(255, 178, 115, 0.25);
  --link-active: #498ff8;
  --link-visited: #42638d;
  --theme-green: #2e9519;
  --theme-warning: #d72525;
  --theme-warning-selected: #bc2222;
  --text-color: #333;
  --light-text-color: #707070;
  --disabled-text-color: #808080;
  --table-row-bg: #f4f4f4;
  --table-row-bg-disabled: #f9f9f9;
  --outline-color: #cccccc;
  --separator-line: #dbdbdb;
  --border-line-color: #ebebeb;
  --inline-code: #b1dbff;
  --code-token: #a3ccff43;
  --correct: #35a826;
  --incorrect: #c33939;
  --theme-bg: #1c3345;
}

.custom-textarea {
  border-radius: 5px;
  font-size: 14px;
  padding: 9px;
  border: 1px solid transparent;
  outline: none;
  margin-bottom: 5px;
  margin: 2px 0px;
  box-sizing: border-box;
  background-color: #cfe9ff30;
  color: #d7edffd8;
  font-family: 'SF Mono', 'Menlo', monospace;
}

.custom-textarea::placeholder {
  color: rgba(183, 195, 208, 0.7);
}

.custom-textarea:focus {
  box-shadow:  0 0 0 3px rgba(113, 184, 255, 0.2);
  -webkit-box-shadow: 0 0 0 3px rgba(113, 184, 255, 0.2);
  border: 1px solid rgba(79, 165, 252, 0.5);
}

.custom-textfield {
  width: calc(100% - 20px);
  border: 0;
  background-color: #fefefe;
  transition-duration: 0.2s;
  padding: 9px;
  font-size: 14px;
  border-radius: 5px;
  margin: 5px 0px;
  border: 1px solid transparent;
  font-weight: 500;
}

.custom-textfield:disabled {
  color: #404040;
}

.custom-textfield:hover:not(:disabled) {
  background-color: #fefefe;
  box-shadow: 0 0 0 3px var(--focus-tint);
  -webkit-box-shadow: 0 0 0 3px var(--focus-tint);
  border: 1px solid #eaeaea;
}

.custom-textfield:focus:not(:disabled) {
  /* box-shadow: none;
  outline: none; */
  outline: none;
  box-shadow:  0 0 0 3px rgba(250,140,70,0.15);
  -webkit-box-shadow: 0 0 0 3px rgba(250,140,70,0.15);
  background-color: #fefefe;
  border: 1px solid rgba(250,140,70,0.5);
}

.custom-textfield:active {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid #ebeaea;
  /* border-width: 1px; */
}

.center-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.full-screen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.full-screen-with-navbar {
  position: absolute;
  left: 0;
  right: 0;
  top: 65px;
  bottom: 0;
}

a:link, a:visited {
  color: unset;
  text-decoration: none;
}


.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: #f0f0f030;
  background-repeat: no-repeat;
  background-position: 50%;
  flex-shrink: 0;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}

.gutter.gutter-vertical {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
  cursor: row-resize;
}

.loader {
  border: 3px solid #e8e8e8; /* Light grey */
  border-top: 3px solid #8d8d8d;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.generic-table {
  margin: 15px;
  border-collapse: collapse;
}

.generic-table td {
  font-size: 15px;
  vertical-align: middle;
  text-align: center;
  width: 50px;
  height: 50px;
  border: 1px solid #e9e9e9;
  padding: 2px;
}

table .value-cell {
  outline: none;
  resize: none;
  width: 100%;
  border: none;
  padding: 5px;
  overflow: hidden;
  box-sizing: border-box;
  /* min-height: 50px; */
  font-family: 'SF Mono', 'Menlo', monospace;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 0px;
  background-color: transparent;
  vertical-align: middle;
}