.filter-table {
    cursor: grab;
    border-collapse: collapse;
    border-radius: 4;
    background-color: #f7f7f7;
    box-shadow: 0x 2px 8px 2px rgba(0, 0, 0, 0.1);
    user-select: none;
    font-size: 12px;
}

.filter-table:active {
    opacity: 0.35;
}

.filter-table:active {
    cursor: grabbing;
}

.filter-table td {
    width: 22px;
    height: 22px;
    border: 1px solid #5ab15d;
    background-color: #eaf9eba0;
    user-select: none;
    cursor: grab;
}

.filter-table td:active {
    cursor: grabbing;
}