.spam-table {
    margin: 10px auto;
    border-collapse: collapse;
}

.spam-table th {
    padding: 5px;
    font-weight: 600;
}

.spam-table td {
    border: 1px solid var(--outline-color);
    text-align: center;
    height: 100px;
    width: 100px;
}

.spam-table td input {
    width: 100%;
    height: 100%;
    border: none;
    text-align: center;
    font-size: 22px;
    /* outline: none; */
    background-color: transparent;
}

.spam-table td input::-webkit-outer-spin-button,
.spam-table td input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.stats-table {
    margin: 15px auto 20px;
}

.stats-table td:first-child {
    font-weight: 600;
}

.stats-table td:nth-child(2), .stats-table td:nth-child(3) {
    text-align: right;
    height: 35px;
}

.stats-table .color-token {
    border-radius: 3px;
    width: 12px;
    height: 12px;
    display: inline-block;
    margin: 0px 4px;
}