.blockspace {
    position: relative;
    border: 1px dashed lightgray;
}

.blockspace .blockspace-canvas {
    position: relative;
    background-color: #fbfbfb;
    overflow: auto;
    height: 90vh;
    user-select: none;
}

.blockspace .sidebar {
    position: absolute;
    width: 300px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
    border-right: 1px solid #f0f0f0;
}

.blockspace .alert-container {
    background-color: rgba(10, 10, 10, 0.15);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
}

.blockspace .alert-content {
    z-index: 6;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    min-width: 100px;
    min-height: min-content;
    padding: 20px;
}

.blockspace .alert-title {
    font-size: 18px;
    font-weight: 600;
    margin: 10px;
    text-align: center;
    margin-bottom: 12px;
}

.alert-button-group {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    justify-content: space-evenly;
    gap: 5px;
}

.alert-button {
    border-radius: 50px;
    font-weight: 500;
    padding: 7px 16px;
    min-width: 100px;
    font-size: 14px;
    border-style: none;
    background-color: white;
    transition-duration: 0.2s;
    cursor: pointer;
}

.alert-button:disabled {
    cursor: not-allowed;
}

.alert-button-normal {
    border: 1px solid #eaeaea;
}
  
.alert-button-default {
    font-weight: 600;
    color: white;
    background-color: var(--theme-1);
}

.alert-button-default:disabled {
    background-color: var(--theme-disabled);
    cursor: not-allowed;
}

.alert-button-default-hollow {
    font-weight: 600;
    color: var(--theme-1);
    border: 1.5px solid var(--theme-1);
}

.alert-button-default-hollow:disabled {
    color: var(--theme-disabled);
    border: 1.5px solid var(--theme-disabled);
    cursor: not-allowed;
}

.alert-button-normal:hover {
    background-color: #f5f5f5;
}

.alert-button-normal:enabled:active {
    color: black;
    background-color: #f0f0f0;
}

.alert-button-default:enabled:active {
    background-color: var(--theme-select);
}

.alert-button-destructive {
    background-color: var(--theme-warning);
    color: white;
}

.alert-button-destructive:enabled:hover {
    background-color: var(--theme-warning-selected);
}

.blockspace .right-sidebar {
    position: absolute;
    right: 0;
    height: 100%;
    border-left: 1px solid #f0f0f0;
    z-index: 1;
    background-color: white;
    overflow-x: hidden;
}

.blockspace .show-inspector-icon {
    position: absolute;
    z-index: 2;
    top: 6px;
    right: 6px;
    cursor: pointer;
}

.blockspace .right-sidebar .close {
    position: absolute;
    left: 5px;
    top: 5px;
    opacity: 0.8;
    transition-duration: 0.1s;
}

.blockspace .right-sidebar .close:hover {
    opacity: 0.9;
}

.blockspace .right-sidebar .close:active {
    opacity: 1.0;
}

.blockspace .right-sidebar .inspector-view {
    position: absolute;
    width: 100%;
    height: 100%;
}

.blockspace .right-sidebar .inspector-view .menu-bar {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.blockspace .right-sidebar .inspector-view .menu-bar > img {
    width: 20px;
    height: 20px;
    padding: 3px;
    border-radius: 4px;
    margin: 1px;
}

.blockspace .right-sidebar .inspector-view .menu-bar > img:hover {
    background-color: #f1f9ff;
}

.blockspace .right-sidebar .inspector-view .menu-bar > img.selected {
    background-color: #e2f1ff;
}
