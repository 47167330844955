.navbar-container {
    background-color: white;
    top: 0%;
    position: fixed;
    width: 100vw;
    z-index: 9;
    overflow-y: hidden;
    overflow-x: auto;
    padding-right: 5px;
    border-bottom: 1pt solid #e5e5e5;
    box-shadow: 0 2px 8px -1px rgba(30, 30, 30, 0.1);
    /* padding-left: 70px; */
    height: 65px;
    display: flex;
    align-items: center;
}

.navbar-container a {
    color: black;
}