.embedded-playground-root {
    margin: 15px 2px;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
    overflow: hidden;
}

.embedded-playground-container {
    position: relative;
    overflow: hidden;
}


.embedded-playground-container .embedded-canvas {
    background-color: #f7f7f7;
    overflow: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.embedded-playground-header {
    padding: 9px 11px;
    font-size: 15px;
    color: #141414;
    line-height: 20px;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
}
