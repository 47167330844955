.exercise .bottom-bar {
    /* background-color: white; */
    border-top: 1px solid #eee;
    position: relative;
    overflow: hidden;
    max-height: 60%;
    flex-shrink: 0;
}

.exercise .bottom-bar:not(:active) {
    transition-duration: 0.25s;
}

.exercise .bottom-bar .status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 20px;
    height: 65px;
    box-sizing: border-box;
    width: 100%;
    background: var(--theme-bg);
    /* border-bottom: 1px solid #eee; */
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
}

.bottom-bar {
    --button-normal: rgb(65, 171, 65);
    --button-pressed: rgb(59, 159, 59);
    --button-pause: rgb(228, 228, 228);
    --button-pause-pressed: rgb(211, 211, 211);
}

.bottom-bar .handle {
    position :absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 3px;
    background-color: #e2e2e2;
    cursor: ns-resize;
    user-select: none;
}

.bottom-bar button {
    border-radius: 50px;
    border: none;
    color: white;
    font-weight: 600;
    font-size: 16px;
    padding: 10px 22px;
    width: 150px;
    min-width: fit-content;
    cursor: pointer;
    transition-duration: 0.15s;
    margin: 0px 5px;
    flex-shrink: 1;
}

.bottom-bar .next-button {
    background-color: rgb(103, 146, 227);
}

.bottom-bar button#submit.normal {
    background-color: var(--button-normal);
    color: white;
}

.bottom-bar button#submit.running {
    background-color: var(--button-pause);
    color: var(--text-color);
}

.bottom-bar button#submit.normal:active:not(:disabled) {
    background-color: var(--button-pressed);
    color: white;
}

.bottom-bar button#submit.running:active:not(:disabled) {
    background-color: var(--button-pause-pressed);
    color: var(--text-color);
}


.bottom-bar #submit:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.bottom-bar .next-button:active {
    background-color: rgb(86, 128, 204);
}

.bottom-bar .test-cases {
    position: absolute;
    top: 65px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 10px;
    /* box-sizing: border-box; */
}

.bottom-bar .test-cases .test-case {
    padding: 5px 10px;
    box-sizing: border-box;
    height: fit-content;
    min-height: 80px;
    display: flex;
    align-items: center;
    background-color: #f5f5f540;
    margin-bottom: 5px;
    border-radius: 5px;
    gap: 25px;
    overflow-x: hidden;
}

.bottom-bar .test-cases .test-status {
    display: flex;
    margin-left: 30px;
    align-items: center;
    flex-shrink: 0;
    min-width: 100px;
}

.bottom-bar .test-cases .test-detail {
    flex-grow: 2;
    /* background-color: #f5f5f540; */
    position: relative;
    border-spacing: 0px 6px;
}


.bottom-bar .test-cases .test-detail td {
    padding: 0px;
    max-height: unset;
}

.bottom-bar .test-prompt {
    text-align: center;
    font-size: 15px;
    color: var(--light-text-color);
    padding: 30px;
}

.fading-star {
    position: absolute;
    width: 100%;
    height: 100%;
    scale: 1;
    transition: opacity 0.6s, scale 0.6s;
}

.fading-star.filled.hidden {
    scale: 2;
}

.fading-star.hidden {
    opacity: 0;
}